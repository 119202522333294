<template>
  <n-grid
    :label="$t('OrderList.myOrders')"
    src="api/v1/orders"
    order-by="fechaProceso"
    sort-by="asc"
    col-sm="12"
    col-md="12"
    col-lg="12"
    col-xl="12"
  >
    <template #item="{ item }">
      <base-card @click="() => $router.push(`/entities/${$route.params.entidadId}/account/orders/${item.ocId}`).catch(() => {})">
        <v-row class="px-4 mt-2" align="center" justify="center">
          <v-col cols="6" md="3" sm="12">
            <h4 class="font-600">{{ item.ocId }}</h4>
          </v-col>

          <v-col cols="6" md="3" sm="12">
            <v-chip
              color="grey lighten-2"
              small
              v-if="item.tblLgtEstatusOc"
            >
              {{ item.tblLgtEstatusOc.estatusOc }}
            </v-chip>

            <v-icon v-else>mdi-clock</v-icon>
          </v-col>

          <v-col cols="6" md="3" sm="12">
            {{ getDate(item.fechaOc) }}
          </v-col>

          <v-col cols="6" md="2" sm="12">
            $ {{ (item.total || 0).toFixed(2) }}
          </v-col>

          <v-col cols="12" md="1" sm="12" class="text-right">
            <v-btn
              icon
              color="grey"
              @click="() => $router.push(`/entities/${$route.params.entidadId}/account/orders/${item.ocId}`).catch(() => {})"
            >
              <v-icon>mdi-arrow-right</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </base-card>
    </template>
  </n-grid>
</template>

<script>
import moment from 'moment';
import NGrid from '../../../components/newgen/NGrid.vue'

export default {
  components: {
    NGrid,
  },
  methods: {
    getDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
  },
};
</script>